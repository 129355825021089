@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
// body {
//   font-family: "Nunito Sans", sans-serif;
//   font-size: 14px;
//   color: #000;
// }

:root {
  --background-color-light: #ffffff;
  --text-color-light: #000000;
  --background-color-dark: #333;
  --text-color-dark: #ffffff;
  --leftmenu-background-dark: #222;
  --dropdown-background-light: #ffffff;
  --dropdown-text-light: #000000;
  --dropdown-background-dark: #444444;
  --dropdown-text-dark: #ffffff;
  --table-background-light: #ffffff;
  --table-text-light: #000000;
  --table-background-dark: #333333;
  --table-text-dark: #ffffff;
  --table-border-color-light: #dee2e6;
  --table-border-color-dark: #444;
}

body {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.light-theme {
  background-color: var(--background-color-light);
  color: var(--text-color-light);
  --json-base00: #ffffff;
  --json-base01: #333333;
  --json-base02: #ebebeb;
  --json-base03: #999999;
  --json-base04: #000000;
  --json-base05: #333333;
  --json-base06: #666666;
  --json-base07: #002b36;
  --json-base08: #ff6f6f;
  --json-base09: #cb4b16;
  --json-base0A: #ffff6f;
  --json-base0B: #9fff6f;
  --json-base0C: #6c71c4;
  --json-base0D: #586e75;
  --json-base0E: #2aa198;
  --json-base0F: #ff9f9f;
}

body.dark-theme {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
  scrollbar-color: #555 #333; /* Scrollbar color for Firefox */
  --json-base00: #333333;
  --json-base01: #f5f5f5;
  --json-base02: #eeeeee;
  --json-base03: #cccccc;
  --json-base04: #000000;
  --json-base05: #333333;
  --json-base06: #666666;
  --json-base07: #ffffff;
  --json-base08: #ff6f6f;
  --json-base09: #ff9f6f;
  --json-base0A: #ffff6f;
  --json-base0B: #9fff6f;
  --json-base0C: #6fffff;
  --json-base0D: #6f6fff;
  --json-base0E: #ff6fff;
  --json-base0F: #ff9f9f;

  ::-webkit-scrollbar-track {
    background: #333; /* Background color of the scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #555; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #777; /* Thumb color on hover */
  }

  ::-ms-scrollbar-track {
    background: #333; /* Background color of the scrollbar track */
  }

  ::-ms-scrollbar-thumb {
    background-color: #555; /* Color of the scrollbar thumb */
  }

  input[type="date"].form-control {
    background-color: var(--dropdown-background-dark);
    color: var(--text-color-dark);
    border: 1px solid #444444;
  }

  input[type="date"].form-control ::-webkit-calendar-picker-indicator {
    color: #ff0000; /* Your desired icon color */
    opacity: 1; /* Ensure the icon is visible */
  }

  svg {
    color: var(--text-color-dark);
  }

  .dropdown-menu {
    background-color: var(--dropdown-background-dark);
    color: var(--dropdown-text-dark);
  }
  .dropdown-item {
    color: var(--dropdown-text-dark);
    &:hover {
      background-color: #333333;
    }
  }
  .text-muted {
    color: #ccc !important;
  }
  .text-dark {
    color: inherit !important; /* or 'initial' or 'unset' */
  }
  .leftside-menu {
    background: var(--leftmenu-background-dark);
    color: var(--text-color-dark);
    .side-nav {
      .side-nav-link {
        color: #fff;
        &.active {
          background: var(--leftmenu-background-dark);
          color: #476ef7;
        }
      }
    }
  }
  .pagination {
    --bs-pagination-bg: var(--table-background-dark) important;
    --bs-pagination-color: var(--table-text-dark) important;
    --bs-pagination-active-border-color: var(--table-background-dark) important;
    // --bs-pagination-active-bg : inherit important;
    --bs-pagination-disabled-bg: inherit important;
    --bs-pagination-disabled-color: inherit important;
    // border-color: var(--table-border-color-dark) important;
  }
  .table-responsive {
    background-color: var(--table-background-dark) important;
    color: var(--table-text-dark) important;
    border-color: var(--table-border-color-dark) important;
  }
  .table {
    --bs-table-bg: inherit;
    --bs-table-color: #fff;
    --bs-table-striped-color: inherit;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid var(--table-border-color-dark);
    border-width: 2px;
  }
  .table-bordered tbody tr td {
    --bs-table-bg: inherit important;
    --bs-table-color: var(--table-text-dark) important;
    --bs-table-striped-color: var(--table-text-dark) important;
  }
  .select__control,
  .css-13cymwt-control,
  .css-t3ipsp-control,
  .css-16xfy0z-control {
    background-color: var(--dropdown-background-dark) important;
  }
  .css-1jqq78o-placeholder,
  .css-tj5bde-Svg,
  .css-1dimb5e-singleValue,
  .rpv-core__minimal-button {
    color: var(--dropdown-text-dark);
  }
  .rpv-zoom__popover-target-arrow {
    border-color: var(--dropdown-text-dark) transparent transparent transparent;
  }
  .css-b62m3t-container {
    color: #000000;
  }
  .css-1p3m7a8-multiValue {
    background-color: #7a7a7a;
  }
  .css-wsp0cs-MultiValueGeneric {
    color: white;
  }
  .modal {
    --bs-modal-bg: var(--dropdown-background-dark);
    color: var(--dropdown-text-dark);
  }
  .toolbar button,
  .toolbar input,
  .toolbar select,
  .toolbar textarea {
    color: var(--text-color-dark);
    background: #ffeadb14;
  }
  .dark-icon-color {
    color: #0d6efd;
  }
  /* Icon color on button hover */
  .btn-outline-primary:hover .dark-icon-color {
    color: var(--dropdown-text-dark); /* Hover color for the icon */
  }
  .pure-tree label {
    color: var(--dropdown-text-dark);
  }
  .table tr.active-row td {
    background: #575353;
  }
  .text-danger svg {
    color: #dc3545;
  }
  .btn-link svg {
    color: inherit;
  }
  .form-floating > label {
    color: var(--dropdown-text-light);
  }
  .bg-white {
    background-color: #454545 !important;
  }
  .rpv-core__inner-page {
    background-color: var(--background-color-dark);
  }
  .draggable-content {
    background-color: var(--background-color-dark);
  }
  .draggable-container {
    background: var(--background-color-dark);
  }
  .draggable-header {
    background: var(--leftmenu-background-dark);
  }
  .drag-file-title {
    color: var(--text-color-dark);
  }
  .list-group-item {
    background-color: #7a7a7a;
  }
  .three-dots svg{
    color: #000000;
  }
}

.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-toggle {
  display: none;
}

.toggle-label {
  margin-right: 10px;
  margin-left: 10px;
  width: 40px;
  height: 15px;
  background-color: #ccc;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-label::after {
  content: "";
  width: 13px;
  height: 13px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.3s ease;
}

.theme-toggle:checked + .toggle-label {
  background-color: #333333;
}

.theme-toggle:checked + .toggle-label::after {
  transform: translateX(25px);
}

/* Table styles */
.table-responsive {
  background-color: var(--table-background-light);
  color: var(--table-text-light);
  border-color: var(--table-border-color-light);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.table thead th,
.table tbody tr {
  background-color: inherit;
  color: inherit;
}

.font-fjalla {
  font-family: "Fjalla One", sans-serif;
}
.text-orange {
  color: #ff8d24;
}
.fs-sm {
  font-size: 12px;
}
.hero-content {
  .logo {
    img {
      max-width: 230px;
    }
  }
  .file-upload {
    border: #cfcfcf 3px dashed;
    border-radius: 15px;
  }
}
.footer {
  a {
    text-decoration: none;
    color: #000;
  }
}
.uploading {
  max-width: 750px;
  margin: 10px auto;
  .progress {
    height: 8px;
  }
}
.btn {
  padding: 8px 40px;
  border-radius: 5px;
  font-weight: 600;
}
.btn-danger {
  background-color: #ff653e;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #df3308;
    color: #fff;
  }
}
.processing {
  max-width: 650px;
  margin: 10px auto;
  .progress {
    height: 8px;
  }
}
.text-success {
  color: #1bc48c;
}
.login-container {
  max-width: 450px;
  .logo {
    max-width: 220px;
  }
}
.profile-info,
.noti-info {
  .dropdown-toggle {
    padding: 5px;
    width: 40px;
    height: 40px;
    &::after {
      display: none;
    }
  }
}
.navbar-brand {
  img {
    max-height: 70px;
  }
}
.leftside-menu {
  width: 250px;
  background: #fafafa;
  transition: all 0.25s ease-in-out;
  .logo-left {
    //max-width: 150px;
    margin: 0 auto 20px;
    transition: all 0.25s ease-in-out;
    .logo {
      max-width: 130px;
    }
  }
  .side-nav {
    .side-nav-link {
      padding: 15px 20px;
      padding-right: 10px;
      color: #000;
      display: flex;
      text-transform: uppercase;
      text-decoration: none;
      position: relative;
      &.active {
        color: #476ef7;
        background: #fff;
        font-weight: 700;
        &::before {
          content: " ";
          background: #476ef7;
          color: #fff;
          position: absolute;
          width: 3px;
          height: 30px;
          top: 10px;
          left: 0;
        }
      }
    }
  }
  &.condensed {
    width: 70px;
    .side-nav-item {
      span {
        display: none;
      }
    }
  }
}
.noti-info {
  .noti-number {
    width: 20px;
    height: 20px;
    font-size: 10px;
    right: -5px;
    top: -5px;
    line-height: 20px;
  }
}
.pure-tree {
  text-align: left;
  display: block;
  &.main-tree {
    width: 100%;
    display: inline-block;
  }
  &:not(.main-tree) {
    padding-left: 1.2em;
    li {
      overflow: hidden;
      display: block;
    }
  }
  label {
    display: block;
    color: #717780;
    border-bottom: 1px dashed #b0b9c5;
    padding: 0.65em 0.75em 0.65em 0;
  }
  .pure-tree_link {
    a {
      padding: 0.5em 1.125em 0.5em 0;
      display: block;
      border-radius: 0.2em;
      color: #476ef7;
      text-decoration: none;
      font-size: 12px;
      &:hover {
        color: #434a58;
      }
    }
  }
  &.nested {
    padding-left: 1.7em;
  }
  [type="checkbox"] {
    display: none;
    &:checked + label {
      color: #434a58;
      border-bottom-color: #434a58;
    }
    &:checked ~ ul > li {
      height: auto;
    }
  }
}
.fetched-content {
  pre {
    white-space: pre-wrap;
    font-size: 14px;
    margin: 0;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.button-toggle-menu {
  border: none;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #476ef7;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
  position: relative;
}
.logo-sm {
  display: none;
}
.condensed {
  .logo {
    display: none;
  }
}
.enlarge-modal.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  .modal-dialog {
    max-width: 100%;
    width: auto !important;
  }
}
.overlay {
  background: (rgba(0, 0, 0, 0.1));
  border-radius: 12px;
}
.disabled-option {
  color: #ccc;
  cursor: not-allowed;
}
.dropdown {
  .dropdown-toggle {
    font-size: 14px;
    &.custom {
      &::after {
        display: none;
      }
    }
  }
  .dropdown-menu {
    font-size: 14px;
  }
}
.filter-select {
  min-width: 200px;
}
.selected-files {
  max-height: 300px;
  overflow-y: auto;
}
.unprocess-list {
  max-height: 63vh;
  overflow-y: auto;
  margin: 0;
}
.custom-scroll::-webkit-scrollbar {
  width: 0.75em;
}
.custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ff8d24;
}
.upload-progress {
  max-width: 500px;
}
.table {
  tr {
    &.active-row {
      td {
        background: #f2f2f2;
      }
    }
  }
}

.arrow-icon:hover {
  cursor: "pointer";
  color: #007bff; /* Change to your desired hover color */
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

/* CSS for the draggable modal */
#root {
  position: relative; /* or absolute, if appropriate */
}

.drag-no-select {
  user-select: none; // Disables text selection
}

/* Container styles */
.draggable-container {
  background: white;
  border-bottom: 1px solid #ddd;
  z-index: 1000;
  flex-direction: column;
  max-width: 90vw;
  max-height: 90vh;
  border-radius: 15px;
  padding: 0 0 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

/* Header styles */
.draggable-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background: #e0e0e0;
  border-top-left-radius: 8px; /* Rounded corners */
  border-top-right-radius: 8px; /* Rounded corners */
  border: 1px solid #ccc; /* Subtle bottom border */
}

.drag-file-title {
  flex-grow: 1;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line; /* Single line to avoid wrapping */
  font-size: 12px; /* Consistent font size */
  font-family: Arial, sans-serif; /* Simple, readable font family */
  color: #333; /* Dark color for better readability */
}

/* popup Close button styles */
.drag-close-button {
  cursor: pointer;
  font-size: 18px; /* Larger icon for better visibility */
  color: #888; /* Light color for a subtle look */
  border-radius: 50%; /* Circular button */
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.drag-close-button:hover {
  background-color: #000000; /* Red background on hover */
  color: white; /* White icon color on hover */
}

/* Content styles */
.draggable-content {
  position: relative; /* Added for positioning controls inside */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 8px;
}

.drag-image-container,
.drag-pdf-container {
  flex: 1;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.drag-image-container {
  display: flex;
  justify-content: center; /* Horizontally center the image */
}

.drag-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* PDF controls styles */
.drag-pdf-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1001; /* Ensure controls stay on top */
}

.drag-pdf-container {
  margin-top: 2.5rem;
}

.drag-handle {
  cursor: move; // This changes the cursor to a move pointer when hovering over the header
}

.ql-editor {
  min-height: 250px;
}
/* Basic editor styles */
.toolbar {
  button,
  input,
  select,
  textarea {
    background: rgba(61, 37, 20, 0.08);
    border-radius: 0.5rem;
    border: none;
    color: #000;
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.15;
    margin: 0 3px 0 0;
    padding: 0.375rem 0.625rem;
    transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
    &:hover {
      background: rgba(61, 37, 20, 0.12);
      color: #110f0e;
    }
    &.is-active {
      background: #6a00f5;
      color: #fff;
    }
  }
}
.tiptap {
  padding: 1.5rem;
  border: #dee2e6 1px solid;
  border-radius: 0.5rem;
  margin: 1rem 0;
  min-height: 200px;
  :first-child {
    margin-top: 0;
  }
  &:focus {
    outline: none;
  }
  img {
    max-width: 100%;
  }
  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */
  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: "JetBrainsMono", monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }
}
/* Custom editor styles */
.issue-desc img {
  max-width: 100%;
}
.proj-users {
  max-height: 400px;
  overflow-y: auto;
}
.vertical-align-middle {
  vertical-align: middle !important;
}
.dropdown-toggle.no-arrow::after {
  display: none;
}
.btn-link {
  padding: 2px 5px;
}
.issue-slide {
  padding: 10px 40px;
  background: #f4f4f4;
  border-radius: 10px;
  .carousel-control-prev,
  .carousel-control-next {
    width: 40px;
    background: rgba(0, 0, 0, 0.1);
  }
}
.model-select {
  min-width: 200px;
}
.btn-round {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
}
.custom-select {
  min-width: 200px;
  .custom-select__control {
    background: #0d6efd;
    border: none;
    border-radius: 15px;
    color: #fff;
    padding: 3px 10px;
    &:hover {
      border: none;
    }
    .custom-select__single-value {
      color: #fff;
      font-weight: 700;
    }
  }
  .custom-select__dropdown-indicator {
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

/* Custom styles for the json-viewer */
.json-viewer-container {
  font-family: monospace;
  font-size: 14px;
  width: 100%;
  /* background-color: #f9f9f9; */
  /* padding: 10px; */
  /* border: 1px solid #ccc; */
 border-radius: 15px;
  overflow-x: auto;
}

@media (min-width: 768px) {
  .json-viewer-container {
    flex-direction: row;
  }
}

.json-row-container {
  border-radius: 5px;
  min-height: 25px;
  margin: 8px;
  margin-left: 28px;
  background-color: #e3e3e3;
  box-sizing: border-box;
}

.json-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  letter-spacing: 0.5px;
  padding: 0px 5px 0px 15px;
  /* background-color: #e3e3e3; */
  box-sizing: border-box;
}

.key-container {
  display: flex;
  align-items: center;
}

.caret-icon {
  cursor: pointer;
  margin-right: 8px;
}

.json-key {
  color: #002b36;
  margin-right: 5px;
}

.json-value {
  padding-top: 5px;
  /* padding-bottom: 5px; */
  /* margin-right: 10px; */
  word-break: break-word;
}

.three-dots-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.three-dots {
  display: flex;
  cursor: pointer;
  padding: 7px 7px 7px 0px;
 
}

.three-dots.active {
  cursor: pointer;
  padding: 7px 7px 7px 0px;
  display: flex;
  /* background-color: gray; */
}

.review-box {
  display: flex; /* Ensure it's a flex container */
  gap: 5px; /* Set the gap between items */
  align-items: center; /* Align items vertically */
  padding-left: 10px;
  padding-right: 5px;
  /* padding-top: 5px; */
}

.three-dots.active + .review-box {
  display: flex; /* Ensure it's displayed as flex when active */
  padding-top: 3px;
}

.tick{
  /* padding-top: 3px; */
  color: green;
}
.cross {
  /* padding-top: 3px; */
  color: red;
}

.review-result {
  /* padding-top: 3px; */
  /* display: flex;
  align-items: center;*/
  /* margin-left: 8px;  */
  display: inline-flex;
  align-items: center;
}

.json-object {
  padding-left: 20px;
  /* Indents the nested JSON */
  width: 100%;
  /* Ensures that nested objects start on the next line */
}

.json-string {
  color: green;
  /* Change to your desired color for string values */
}

.json-boolean {
  color: blue;
  /* Change to your desired color for boolean values */
}

.json-number {
  color: orange;
  /* Change to your desired color for number values */
}

.json-null {
  color: red;
  /* Change to your desired color for null values */
}

.json-row-container.selected-tick {
  background-color: #d4edda;;
}

.json-row-container.selected-cross {
  background-color: #f8d7da;
}

.review-tick {
  margin-top: 2px;
  color: green; /* Green color for tick */
}

.review-cross {
  margin-top: 2px;
  color: red; /* Red color for cross */
}


.icon-container {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 56px;; /* Adjust as needed */
  margin: -8px;
}

.round-rect-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  border-radius: 5px;
  border: 1px solid transparent;
  margin: 5px;
  color: white;
}

.round-rect-box.tick {
  background-color: green;
}

.round-rect-box.cross {
  background-color: red;
}
.round-rect-box.submit {
  background-color: #0d6efd;
  &:hover {
    background-color: #0a58ca;
  }
}
.round-rect-box.submit.disabled {
  /* Styles for the disabled button */
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.count {
  margin-left: 5px; /* Adjust as needed */
}
.dashboard-container{
  input[type="date"].form-control{
    font-size: 14px;
    height: 38px;
  }
  .react-datepicker-wrapper{
    width: 100%;
    .react-datepicker__view-calendar-icon{
      input{
        width: 100%;
        border: #dee2e6 solid 1px;
        font-size: 13px;
        &:focus{
          border-color: #dee2e6;
        }
      }
    }
  }
  .date-list{
    max-height: 220px;
    overflow-y: auto;
  }
}
.icon-bx {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background: #f4f7fe;
  color: #422afb;
}
.box-content {
  flex: 1 1 0%;
  margin-top: auto;
  margin-bottom: auto;
  margin-inline-start: 18px;
  p {
    color: #a3aed0;
  }
}
.tag-selection-bx{
  width: 100%;
  top: 100%;
  left: 0;
  padding: 10px;
  z-index: 100;
  border-radius: 10px;
}